<template>
  <div class="c-subHeaderInner">
    <div class="c-subMenu">
      <nav class="c-searchMenu">
        <ul
          v-if="headerType === HEADER_TYPE.MZHC"
          class="c-searchMenu_list"
        >
          <li class="c-searchMenu_item">
            <SearchMenuCategory
              :categories="masterCategories"
              :header-type="headerType"
            />
          </li>
          <li
            v-if="showSearchMenuPopularUseScene"
            class="c-searchMenu_item"
          >
            <SearchMenuPopularUseScene />
          </li>
          <li class="c-searchMenu_item">
            <SearchMenuJob
              :header-type="headerType"
              :jobs="jobs"
            />
          </li>
        </ul>
        <ul
          v-else
          class="c-searchMenu_list"
        >
          <template v-if="headerType === HEADER_TYPE.PROVIDER">
            <li class="c-searchMenu_item">
              <SearchMenuRequest />
            </li>
          </template>
          <template v-else>
            <li class="c-searchMenu_item">
              <!-- カテゴリから探す -->
              <SearchMenuCategory
                :categories="masterCategories"
                :header-type="headerType"
              />
            </li>
            <li
              v-if="showSearchMenuPopularUseScene"
              class="c-searchMenu_item"
            >
              <!-- 目的から探す -->
              <SearchMenuPopularUseScene />
            </li>
            <li class="c-searchMenu_item">
              <!-- 出品者を探す -->
              <SearchMenuJob :jobs="jobs" />
            </li>
            <li
              v-if="$translate.showJapaneseOnly()"
              class="c-searchMenu_item"
            >
              <!-- ブログを探す -->
              <SearchMenuBlog :blog-categories="blogCategories" />
            </li>
          </template>

          <template v-if="headerType === HEADER_TYPE.BEFORE_LOGIN && $translate.showJapaneseOnly()">
            <li
              v-if="headerType === HEADER_TYPE.BEFORE_LOGIN"
              class="c-divider"
            ></li>
            <li class="c-searchMenu_item isPC">
              <SearchMenuRequest />
            </li>
            <li class="c-searchMenu_item c-searchMenu_item-noMargin isTB">
              <SearchMenuJobMatching />
            </li>
          </template>
        </ul>
      </nav>
    </div>
    <div
      v-if="$translate.showJapaneseOnly()"
      class="c-subLink"
      :class="{ 'c-subLink-mzhc': headerType === HEADER_TYPE.MZHC }"
    >
      <DButton
        v-if="headerType == HEADER_TYPE.BUYER || headerType == HEADER_TYPE.MZHC"
        href="/job_matching/type_select"
        is-bold
        size="small"
        :type="headerType == HEADER_TYPE.MZHC ? 'contained' : 'outlined'"
      >
        仕事・求人を投稿して募集
      </DButton>
      <div
        v-if="headerType === HEADER_TYPE.BUYER"
        class="c-divider c-divider-secondary"
      ></div>
      <DButton
        v-if="headerType === HEADER_TYPE.PROVIDER"
        href="/services/add"
        is-bold
        size="small"
        type="outlined"
      >
        サービス出品
      </DButton>
      <template v-if="headerType === HEADER_TYPE.BEFORE_LOGIN">
        <div class="c-subLink_item c-subLink_item-noPadding c-subLink_item-secondary">
          <SearchMenuJobMatching />
        </div>
        <div class="c-divider c-divider-secondary"></div>
      </template>
      <template v-if="headerType === HEADER_TYPE.BUYER">
        <a
          class="c-subLink_item c-subLink_item-secondary"
          href="/pages/agents"
        >
          人材を紹介してもらう
          <DNewLabelV2 class="c-newLabel" />
        </a>
      </template>
      <template v-if="HEADER_TYPE.BEFORE_LOGIN === headerType">
        <a
          class="c-subLink_item c-subLink_item-secondary"
          href="/services/add"
        >
          サービス出品
        </a>
      </template>
      <template v-if="[HEADER_TYPE.BEFORE_LOGIN, HEADER_TYPE.PROVIDER].includes(headerType)">
        <div class="c-divider c-divider-secondary"></div>
        <a
          class="c-subLink_item c-subLink_item-secondary"
          @click="goLinkAnchorAgent"
        >
          仕事を紹介してもらう
        </a>
      </template>
      <template v-if="[HEADER_TYPE.BEFORE_LOGIN, HEADER_TYPE.PROVIDER].includes(headerType)">
        <div class="c-divider c-divider-secondary"></div>
        <a
          class="c-subLink_item c-subLink_item-secondary"
          href="/mypage/blogs?new=true"
        >
          ブログを投稿
        </a>
      </template>
      <template v-if="headerType === HEADER_TYPE.BUYER">
        <div class="c-divider c-divider-secondary"></div>
        <a
          class="c-subLink_item c-subLink_item-secondary"
          href="/services/add"
        >
          サービス出品
        </a>
      </template>
      <div
        v-if="headerType === HEADER_TYPE.PROVIDER"
        class="c-divider c-divider-secondary"
      ></div>
      <a
        v-if="headerType === HEADER_TYPE.PROVIDER"
        class="c-subLink_item c-subLink_item-secondary c-subLink_item-profile"
        href="/mypage/user?ref=common_header_sub"
      >
        スキル・経験を登録
      </a>
      <div
        v-if="headerType !== HEADER_TYPE.MZHC"
        class="c-subLink_ellipsis"
      >
        <ODropdown
          aria-role="list"
          :mobile-modal="false"
          :triggers="['hover']"
        >
          <a
            slot="trigger"
            class="c-subLink_trigger"
          >
            <div class="c-subLink_triggerInner">
              <CoconalaIcon
                name="ellipsis-h"
                size="16px"
              />
            </div>
          </a>
          <ODropdownItem
            v-if="headerType === HEADER_TYPE.BUYER"
            aria-role="listitem"
            @click="goLink('/pages/agents')"
          >
            人材を紹介してもらう
            <DNewLabelV2
              v-if="isLoggedIn"
              class="c-newLabel"
            />
          </ODropdownItem>
          <ODropdownItem
            v-if="headerType === HEADER_TYPE.BEFORE_LOGIN || headerType === HEADER_TYPE.BUYER"
            aria-role="listitem"
            @click="goLink('/services/add')"
          >
            サービス出品
          </ODropdownItem>
          <ODropdownItem
            v-if="headerType === HEADER_TYPE.BEFORE_LOGIN"
            aria-role="listitem"
            @click="goLink('/job_matching/type_select')"
          >
            仕事・求人を投稿して募集
          </ODropdownItem>
          <ODropdownItem
            v-if="[HEADER_TYPE.BEFORE_LOGIN, HEADER_TYPE.PROVIDER].includes(headerType)"
            aria-role="listitem"
            @click="goLinkAnchorAgent"
          >
            仕事を紹介してもらう
          </ODropdownItem>
          <ODropdownItem
            v-if="[HEADER_TYPE.BEFORE_LOGIN, HEADER_TYPE.PROVIDER].includes(headerType)"
            aria-role="listitem"
            @click="goLink('/mypage/blogs?new=true')"
          >
            ブログを投稿
          </ODropdownItem>
          <ODropdownItem
            v-if="headerType === HEADER_TYPE.BUYER && !isBusiness"
            aria-role="listitem"
            @click="goLink('/mypage/blogs?new=true')"
          >
            ブログを投稿する
          </ODropdownItem>
          <ODropdownItem
            v-if="headerType === HEADER_TYPE.PROVIDER"
            aria-role="listitem"
            @click="goLink('/mypage/user?ref=common_header_sub')"
          >
            スキル・経験を登録
          </ODropdownItem>
        </ODropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import SearchMenuJobMatching from '~/components/organisms/TheHeader/components/SearchMenuJobMatching.vue'

export default {
  name: 'HeaderSub',
  components: {
    SearchMenuJobMatching,
    SearchMenuCategory: () => import('./SearchMenuCategory'),
    SearchMenuJob: () => import('./SearchMenuJob'),
    SearchMenuBlog: () => import('./SearchMenuBlog'),
    SearchMenuPopularUseScene: () => import('./SearchMenuPopularUseScene'),
    SearchMenuRequest: () => import('./SearchMenuRequest'),
    DButton: () => import('~/components/atoms/DS2/DButton'),
    DNewLabelV2: () => import('~/components/atoms/DS2/DNewLabelV2')
  },
  props: {
    jobs: {
      type: Array
    },
    masterCategories: {
      type: Array
    },
    blogCategories: {
      type: Array
    },
    headerType: {
      type: String,
      required: true
    }
  },
  methods: {
    goLink(link) {
      location.href = link
    },
    goLinkAnchorAgent() {
      if (this.$route.name === 'services-add') {
        setTimeout(() => {
          document.querySelector('#anchor_ca')?.scrollIntoView()
        }, 100)
        return
      }
      location.href = '/services/add#anchor_ca'
    }
  },
  computed: {
    ...mapState('constants', ['HEADER_TYPE']),
    ...mapState('auth/user', ['isBusiness']),
    ...mapGetters('auth', ['isLoggedIn']),
    showSearchMenuPopularUseScene() {
      return (
        this.$translate.showJapaneseOnly() &&
        (this.headerType === this.HEADER_TYPE.BUYER ||
          this.headerType === this.HEADER_TYPE.BEFORE_LOGIN ||
          this.headerType === this.HEADER_TYPE.MZHC)
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.isPC {
  display: block;
}
.isTB {
  display: none;
}
.c-subHeaderInner {
  display: flex;
  margin: auto;
  padding: 0 16px;
  max-width: $breakpoint-xl;
  height: 40px;

  align-items: center;
  justify-content: space-between;
}

.c-searchMenu {
  &_list {
    display: flex;

    align-items: center;
  }

  &_item {
    flex: 1 0 auto;
    white-space: nowrap;
    letter-spacing: 0.02em;
    font-size: 13px;

    & + & {
      margin-left: 16px;
    }

    > a {
      @include link-effect-background(4px);

      display: inline-block;
      color: $ds2-color-gray-900;
    }

    &-noMargin {
      margin: 0 !important;
    }
  }

  .c-divider {
    margin: 0 14px;
    height: 24px;
    border-right: 1px solid $ds2-color-gray-50;
  }
}

.c-subLink {
  display: flex;

  align-items: center;

  ::v-deep .d-button {
    margin-right: 14px;
    height: 24px !important;
  }

  &-mzhc {
    .d-button {
      margin-right: 8px;
    }
  }

  .c-divider {
    margin: 0 14px;
    height: 24px;
    border-right: 1px solid $ds2-color-gray-50;
  }

  &_item {
    @include link-effect-background(4px);

    color: $ds2-color-gray-900;
    white-space: nowrap;
    letter-spacing: 0.02em;
    font-size: 13px;

    &-noPadding {
      padding: 0;
    }

    &-profile {
      margin-right: 0;
    }

    &-skill {
      display: flex;
      margin-right: 0 !important;
    }
  }

  &_ellipsis {
    display: none;
  }

  &_ellipsisItem {
    &-skill {
      display: flex;
    }
  }

  &_new {
    margin-left: 4px;
  }
}

::v-deep .c-menuLv1,
::v-deep .c-menuLv2 {
  overflow-y: auto;
  max-height: calc(100vh - 108px);
}

.c-newLabel {
  vertical-align: 1px;
}

@media (max-width: breakpoint(Header, M)) {
  .isPC {
    display: none;
  }
  .isTB {
    display: block;
  }
  .c-searchMenu {
    &_item {
      &-secondary {
        display: none;
      }
    }
  }
  .c-subLink {
    &_item {
      &-secondary {
        display: none;
      }
    }

    &_servicesAdd {
      &-buyer,
      &-beforeLogin {
        display: none;

        & + .c-divider {
          display: none;
        }
      }
    }

    ::v-deep .d-button {
      margin-right: 24px;
    }

    &-mzhc {
      .d-button {
        margin-right: 8px;
      }
    }

    &_ellipsis {
      display: block;

      ::v-deep .dropdown-menu {
        position: absolute;
        top: 100% !important;
        right: 0;
        left: unset;
        z-index: 19; // ヘッダー上段のバルーンより下にするため

        .dropdown-item {
          color: $ds2-color-gray-900;
          font-size: 13px;
          line-height: 1.5;
        }
      }
    }

    &_trigger {
      color: $ds2-color-gray-900;
      font-size: 13px;
    }

    &_triggerInner {
      @include link-effect-background(4px);
    }

    .c-divider {
      &-secondary {
        display: none;
      }
    }
  }
}
</style>
